import moment from "moment";
import { useAuthStore, useServiceStore, useTimeCardStore } from "../../store";
import { get, put } from "../../types/api";

export const isObjEmpty = (obj: any) => {
  if (
    obj && // 👈 null and undefined check
    Object.keys(obj).length === 0 &&
    Object.getPrototypeOf(obj) === Object.prototype
  ) {
    return true;
  }
  return false;
};

export const removeExtraField = (data:any, removeKeys:any) => {
  return data.map((e:any) => {
    removeKeys.forEach((key:string) => {
      if(e[key]) {
        delete e[key];
      }
    });
    return e;
  })
}

export const arrayMove = (arr: any, fromIndex: number, toIndex: number) => {
  const element = arr[fromIndex];
  arr.splice(fromIndex, 1);
  arr.splice(toIndex, 0, element);
  return arr;
}

export const addCommas = (num: any) => {
  if(num) {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  }
  return num;
};

export const removeNonNumeric = (num: any) => {
  if(num) {
    return num.toString().replace(/[^0-9]/g, "");
  }
  return num;
}

export const getUserAccessAndFilterProjects = async (projectId?: any) => {
  await getUserAccess(projectId);
  getProjectAndForemen(projectId, true);
}

export const getUserAccess = async (projectId?: any) => {
  try {
    const { setUserAccess, userSettings } = useAuthStore.getState();
    const pId = projectId || userSettings.selectedProjectId;
    let apiURL = `/v1/api/user-access/${pId}`;
    // @ts-ignore
    const res = await get(apiURL);
    if (res.data && res.data.data) {
      setUserAccess({
        data: res.data.data
      });
    }
  } catch(error) {
    console.log(error);
  }
};

export const getProjectAndForemen = async (
  projectId: any,
  isUserAccessUpdated = false
) => {
  const { projectsAndSupervisors, setProjectsAndSupervisors } = useTimeCardStore.getState();
  const { companyId } = useServiceStore.getState();

  if(projectId !== projectsAndSupervisors.projectId || companyId !== projectsAndSupervisors.companyId ) {
    let apiURL = `/v1/api/time-card/helper/projects-and-supervisors?orgEntityId=${companyId}&projectId=${projectId}&noOfProjects=100`;
    // @ts-ignore
    const res = await get(apiURL);
    if (res.data && res.data.data) {
      const projectsUpdated = res.data.data.projects.map((e:any) => {
        e['projectDisplayNameUI'] = e.projectDisplayNumber + ": " + e.projectName
        return e;
      });
      const masterProjects = projectsUpdated.filter((e : any) => e._hasSupervisor === 1 && e.id === e.masterProjectId) || [];
      const selectedProject = projectsUpdated.find(
        (e: any) => e.id === Number(projectId)
      );
      const filteredProjects = filterProjectDropdownOptions(projectsUpdated);
      const projAndSupervisor = {
        projects: projectsUpdated,
        originalProjects: JSON.parse(JSON.stringify(projectsUpdated)),
        masterProjects,
        originalMasterProjects: JSON.parse(JSON.stringify(masterProjects)),
        filteredProjects,
        originalFilteredProjects: JSON.parse(JSON.stringify(filteredProjects)),
        supervisors: res.data.data.supervisors,
        companyId,
        projectId,
        selectedProject : selectedProject ? [selectedProject] : []
      };
      setProjectsAndSupervisors({...projAndSupervisor});
      return projAndSupervisor;
    }
    return res;
  } else {
    // projects and supervisors is already updated, update filtered projects 
    if(isUserAccessUpdated) {
      const filteredProjects = filterProjectDropdownOptions(projectsAndSupervisors.projects);
      setProjectsAndSupervisors({...projectsAndSupervisors, filteredProjects});
    }
    return projectsAndSupervisors;
  }
};

export const filterProjectDropdownOptions = (projects:any) => {
  const filteredProjects = [] as any;
  const { userAccess, userSettings } = useAuthStore.getState();
  projects.forEach((project:any) => {
    if(project._budgetCodeCount > 0 && project.allowBudgetCodes === 1){
        // Check to see if allowTimecardMultiProject was enabled
        if(userAccess.allowTimecardMultiProject){
            // Add project no matter what
            filteredProjects.push(project);
        }else{
            // Since "allowTimecardMultiProject" is false, then only the project itself or its
            // child projects should be allowed.  Therefore check if this project is a child of the 
            // current project, or the project itself?
            if(project.id === Number(userSettings.selectedProjectId) || 
            project.masterProjectId === Number(userSettings.selectedProjectId) || 
            project.parentProjectId === Number(userSettings.selectedProjectId)){
                // Add project
                filteredProjects.push(project);
            }
        }
    }
  })
  return filteredProjects;
}

export const updateUserSettings = async (companyId : any, projectId : any) => {
  let apiURL = `/v1/api/user-settings`;
  const { userSettings, setUserSettings } = useAuthStore.getState()
  const payload = {
    userId: userSettings.userId,
    _count: userSettings._count,
    lastPage: userSettings.lastPage,
    selectedCompanyId: companyId || userSettings.selectedCompanyId,
    selectedProjectId: projectId,
    id: userSettings.id,
  };
  // @ts-ignore
  const res = await put(apiURL, payload);
  if (res.status === 200) {
    setUserSettings({
      ...userSettings,
      selectedCompanyId: companyId || userSettings.selectedCompanyId,
      selectedProjectId: projectId,
    });
    getUserAccessAndFilterProjects(projectId);
  }
  return;
};

export const updateTimeCardStatus = async (timecardId: any, updateIndex: any, modalRemark: any) => {
  const url = `/v1/api/time-card/status`;

  const id = isNaN(parseInt(timecardId,10)) ? 0 : parseInt(timecardId,10);
  const timeCardStatus = isNaN(parseInt(updateIndex,10)) ? 1 : parseInt(updateIndex,10);

  const payload: any = {
    id,
    timeCardStatus,
    comments: modalRemark,
    printedDateTime: `${moment().format("DD-MM-YYYY HH:mm A")}`,
  };
  const res = await put(url, payload);
  return res;
}

// prepare cost code payload for labour and equipment 
export const preparePayloadLabourAndEquipment = (eachCode: any, costCodeObj: any, payload: any) => {
  if(eachCode.labourBudgetCodeId) {
    const labourObj = {
      id: eachCode.labourTimeCardBudgetCodeId || 0,
      budgetCodeId: eachCode.labourBudgetCodeId,
      budgetCodeKey: eachCode.labourBudgetCodeKey,
      costTypeClassKey: 'L'
    }
    payload.push({...costCodeObj, ...labourObj});
  };
  if(eachCode.equipmentBudgetCodeId) {
    const equipObj = {
      id: eachCode.equipmentTimeCardBudgetCodeId || 0,
      budgetCodeId: eachCode.equipmentBudgetCodeId,
      budgetCodeKey: eachCode.equipmentBudgetCodeKey,
      costTypeClassKey: 'E'
    }
    payload.push({...costCodeObj, ...equipObj});
  };
}

export const formatServiceTicketDisplayNumber = (e: any) => {
  let serviceTicketDisplayNumber = e.serviceTicketDisplayNumber || "";
  const workOrderNumber = e.workOrderNumber || "";
  const poleNumber = e.poleNumber || "";
  if (serviceTicketDisplayNumber) {
    if (workOrderNumber && poleNumber) {
      serviceTicketDisplayNumber = serviceTicketDisplayNumber.replace("-ST", `-${workOrderNumber}-${poleNumber}-`);
    } else if (poleNumber) {
      serviceTicketDisplayNumber = serviceTicketDisplayNumber.replace("-ST", `-${poleNumber}-`);
    } else if (workOrderNumber) {
      serviceTicketDisplayNumber = serviceTicketDisplayNumber.replace("-ST", `-${workOrderNumber}-`);
    }
  }
  return serviceTicketDisplayNumber;
};

// Retrieve nested properties within an object using a provided path or separator
export const accessNestedProperty = (path: any, obj: any, separator = ".") => {
  const properties = Array.isArray(path) ? path : path.split(separator);
  return properties.reduce((prev: any, curr: any) => prev && prev[curr], obj);
};

// Returns a promise that resolves after a specified delay
export const delay = (t: number) => new Promise(resolve => setTimeout(resolve, t));

// Fetch the PDF content from the specified URL and download it
export const downloadPDF = async (pdfUrl: string, fileName: string) => {
  try {
    const response = await fetch(pdfUrl);
    const blob = await response.blob();
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = fileName;
    link.click();
    URL.revokeObjectURL(url);
  } catch (error) {
    console.error('Error downloading PDF:', error);
    throw error;
  }
};

// Sign the FileURL associated to the reportLog object
export const signFileURL = async (fileUrl: string, fileName: string) => {
  const serviceName = `${process.env.REACT_APP_RISKCAST_API_URL}/api/core`;
  const url = serviceName + '/aws/s3/sign/openfile?fileNameS3URL=' + fileUrl;
  const res = await get(url);
  const signedRequest = res.data.signedRequest;
  await downloadPDF(signedRequest, fileName);
};
