import React, { useState, useEffect, useRef } from "react";
import { get, put } from "../../../../src/types/api";
import { ReactComponent as Close } from "../../../assets/images/close.svg";
import { ReactComponent as TrashRed } from "../../../assets/images/trash-red.svg";
import { ReactComponent as Employee } from "../../../assets/images/employee.svg";
import { ReactComponent as AddPlus } from "../../../assets/images/add.svg";
import { Button } from "reactstrap";
import Loader from "../../shared/Loader/Loader";
import { useServiceStore, useTimeCardStore } from "../../../store";
import SelectDropdown from "../../shared/SelectDropdown/SelectDropdown";
import { ReactComponent as Equipment } from "../../../assets/images/equipment.svg";
import shallow from "zustand/shallow";
import { toast, ToastContainer } from "react-toastify";
import { formatServiceTicketDisplayNumber, preparePayloadLabourAndEquipment } from "../../shared/Helpers";
import ServiceTicketCreate from "../../Landing/ServiceTickets/ServiceTicketCreate";
import { filterProjectDropdownOptions } from "../../shared/Helpers";

interface IAddCodeModalProps {
  addCodeModal: boolean;
  timecardId: number;
  assignedCode: any;
  createTimecard: () => Promise<number>;
  toggleAddCode: () => void;
  setAssignedCode: (data: any) => void;
  getAssignedCode: () => void;
  createTimecardPayload: (
    eachCrew: any,
    index: number,
    sortOrder: number,
    createdTimecardId: number
  ) => any;
  getTimecardById: () => void;
}

interface IAssignedCode {
  id: number;
  ids: number[];
  employeeId: number;
  count: number;
  serviceTicketId: number;
}

interface ITimecardArrayPayload {
  id: number;
  tenantId?: number;
  timeCardId: number;
  budgetCodeId: number;
  projectLocationId: number;
  budgetCodeKey: any;
  timeCardDate: any;
  budgetCodeProjectId: any;
  serviceTicketId: any;
  workOrderCostEventId: any;
  sortOrder: number;
  costTypeClassKey?: string;
}

export interface ISaveCodePayload {
  projectId?: number;
  timeCardId: number;
  timeCardCrew: [];
  timeCardCostCodes: ITimecardArrayPayload[];
}

const AddCodeModal = (props: IAddCodeModalProps) => {
  const [availableCode, setAvailableCode] = useState([] as any);
  const [projectAvailableCode, setProjectAvailableCode] = useState([] as any);
  const [availableCodeLoader, setAvailableCodeLoader] = useState(false);
  const [componentLoader, setComponentLoader] = useState(false);
  const [serviceLoader, setServiceLoader] = useState(false);
  const [selectedServiceTicket, setSelectedServiceTicket] = useState([] as any);
  const [serviceModal, setServiceModal] = useState(false);
  const {
    timeCardDate,
    setSelectedCostCodeProject,
    setServiceTicketsList,
  } = useTimeCardStore.getState();
  const { companyId } = useServiceStore.getState();

  const serviceTicketsList = useTimeCardStore(
    (serviceStore) => serviceStore.serviceTicketsList
  );

  const tokenDetails = useServiceStore(
    (serviceStore) => serviceStore.tokenDetails
  );

  const { selectedCostCodeProject, projectsAndSupervisors } = useTimeCardStore(
    (state) => ({
      selectedCostCodeProject: state.selectedCostCodeProject,
      projectsAndSupervisors: state.projectsAndSupervisors
    }),
    shallow
  );
  const { setProjectsAndSupervisors } = useTimeCardStore.getState();

  /** Add code, get the id, update assignedCode */
  const addCode = (id: number) => {
    let alreadyExistingAssignedIndex: number = props.assignedCode.findIndex(
      (eachAssignedCode: IAssignedCode) => {
        // check if code is already assigned to same service ticket
        if (
          eachAssignedCode.id === id &&
          eachAssignedCode.serviceTicketId === selectedServiceTicket[0].id
        ) {
          return true;
        } else {
          return false;
        }
      }
    );
    if (alreadyExistingAssignedIndex === -1) {
      let selectedAvailableCode = availableCode.find(
        (eachAvailableCode: { id: any }) => eachAvailableCode.id === id
      );
      props.setAssignedCode([
        ...props.assignedCode,
        {
          ...selectedAvailableCode,
          count: 1,
          serviceTicketId: selectedServiceTicket[0].id,
          serviceTicketDisplayNumber: selectedServiceTicket[0].serviceTicketDisplayNumber,
          workOrderNumber: selectedServiceTicket[0].workOrderNumber,
          poleNumber: selectedServiceTicket[0].poleNumber,
          budgetCodeProjectId: selectedCostCodeProject[0].id,
        },
      ]);
      scrollToBottom();
    }
  };

  /** API : GET available code */
  const getAvailableCode = async (project = [] as any) => {
    try {
      if (project.length) {
        const url = `/v1/api/time-card/helper/available-cost-codes?projectId=${project[0].id}&timeCardId=${props.timecardId}`;
        setAvailableCodeLoader(true);
        const { data } = await get(url);
        setAvailableCodeLoader(false);
        if (data && data.data) {
          const filteredData = data.data.filter(
            (e: any) => e.projectId === project[0].id
          );
          setProjectAvailableCode(filteredData || []);
          setAvailableCode(data.data || []);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  /** API : GET service tickets */
  const getServiceTickets = async (project = [] as any, findId: any) => {
    try {
      if (project.length) {
        const url = `/v1/api/service/ticket?companyId=${companyId}&projectId=${project[0].id}`;
        setServiceLoader(true);
        const { data } = await get(url);
        setServiceLoader(false);
        if (data?.results?.data) {
          const res = data?.results?.data.sort((a: any, b: any) => a.id - b.id);
          setServiceTicketsList(res);

          if(findId){
            const findNewServiceTicket = res.find((x: any) => x.id === findId);
            setSelectedServiceTicket([{ ...findNewServiceTicket, label: formatServiceTicketDisplayNumber(findNewServiceTicket) }]);
          }
        } else {
          setServiceTicketsList([]);
        }
      }
    } catch (error) {
      setServiceLoader(false);
      if (error instanceof Error) {
        toast.warn(error.message, {
          autoClose: 5000,
          toastId: 'stListDropdown'
        });
      }
    }
  };

  /** API : GET assigned crew */
  const getAssignedCrew = async () => {
    try {
      if (props.timecardId) {
        const url = `/v1/api/time-card/helper/assigned-crew?timeCardId=${props.timecardId}`;
        const { data } = await get(url);
        let result = [] as any;
        data.data.forEach((eachAssignedCrew: any) => {
          let existingAssignedCrew = result.findIndex(
            (eachResult: any) =>
              eachResult.employeeId === eachAssignedCrew.employeeId
          );
          if (existingAssignedCrew > -1) {
            result[existingAssignedCrew].count =
              result[existingAssignedCrew].count + 1;
            result[existingAssignedCrew].ids.push(eachAssignedCrew.id);
          } else {
            // added ids array for adding all the id of that employeeId
            result.push({
              ...eachAssignedCrew,
              count: 1,
              ids: [eachAssignedCrew.id],
            });
          }
        });
        return result;
      }
    } catch (error) {
      console.log(error);
    }
  };

  /* prepare payload  */
  const timeCardCrewPayload = (assignedCrew: any) => {
    try {
      let createdTimecardId: number;
      let sortOrder = -1;
      const timeCardCrew = [] as any;
      if (assignedCrew && assignedCrew.length) {
        assignedCrew.forEach((eachCrew: any) => {
          if (eachCrew.count === 1) {
            sortOrder++;
            timeCardCrew.push(
              props.createTimecardPayload(
                eachCrew,
                0,
                sortOrder,
                createdTimecardId
              )
            );
          } else {
            let index = 0;
            while (index < eachCrew.count) {
              sortOrder++;
              timeCardCrew.push(
                props.createTimecardPayload(
                  eachCrew,
                  index,
                  sortOrder,
                  createdTimecardId
                )
              );
              index++;
            }
          }
        });
      }
      return timeCardCrew;
    } catch (error) {
      console.log(error);
    }
  };

  /* API to save code */
  const saveCode = async () => {
    try {
      const assignedCrew = await getAssignedCrew();
      let createdTimecardId: number = 0;
      if (!props.timecardId) {
        createdTimecardId = await props.createTimecard();
      }
      const url = `/v1/api/time-card/helper/crew-and-cost-codes`;
      let sortOrder = -1;
      const payload: ISaveCodePayload = {
        projectId: selectedCostCodeProject[0].id,
        timeCardId: props.timecardId,
        timeCardCrew: timeCardCrewPayload(assignedCrew) || [],
        timeCardCostCodes: [],
      };
      const createTimecardPayload = (eachCode: any, index = 0) => {
        sortOrder++;
        const serviceTicketId = eachCode.serviceTicketId
          ? eachCode.serviceTicketId
          : selectedServiceTicket.length
            ? selectedServiceTicket[0].id
            : 0;
        return {
          id:
            eachCode.labourTimeCardBudgetCodeId ||
            eachCode.equipmentTimeCardBudgetCodeId ||
            0,
          tenantId: tokenDetails?.tenantId,
          timeCardId: props.timecardId || createdTimecardId,
          budgetCodeId:
            eachCode.labourBudgetCodeId || eachCode.equipmentBudgetCodeId,
          projectLocationId: 0,
          budgetCodeKey:
            eachCode.labourBudgetCodeKey || eachCode.equipmentBudgetCodeKey,
          timeCardDate: timeCardDate,
          budgetCodeProjectId: eachCode.projectId,
          serviceTicketId: serviceTicketId,
          workOrderCostEventId: 0,
          sortOrder,
        };
      };
      props.assignedCode.forEach((eachCode: any) => {
        if (eachCode.count === 1) {
          const costCodeObj = createTimecardPayload(eachCode);
          preparePayloadLabourAndEquipment(eachCode, costCodeObj, payload.timeCardCostCodes);
        } else {
          let index = 0;
          while (index < eachCode.count) {
            const costCodeObj = createTimecardPayload(eachCode, index);
            preparePayloadLabourAndEquipment(eachCode, costCodeObj, payload.timeCardCostCodes);
            index++;
          }
        }
      });
      const res = await put(url, payload);
      if (res.status === 200) {
        props.getTimecardById();
      }
      props.toggleAddCode();
    } catch (error) {
      console.log(error);
    }
  };

  /** API: DELETE code memeber */
  const deleteAssignedCode = async (index: number) => {
    try {
      props.setAssignedCode([
        ...props.assignedCode.slice(0, index),
        ...props.assignedCode.slice(index + 1),
      ]);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (props.addCodeModal) {
      props.getAssignedCode();
      setSelectedServiceTicket([]);
      if (selectedCostCodeProject && selectedCostCodeProject.length) {
        getAvailableCode(selectedCostCodeProject);
        getServiceTickets(selectedCostCodeProject, null);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.addCodeModal]);

  const listEndRef = useRef<null | HTMLLIElement>(null)

  const scrollToBottom = () => {
    if (listEndRef.current) {
      listEndRef.current?.scrollIntoView({ behavior: "smooth" })
    }
  }

  const handleInputChange = async (e: any) => {
    if (!e || e.trim() === "") {
      setProjectsAndSupervisors({...projectsAndSupervisors, filteredProjects: projectsAndSupervisors.originalFilteredProjects });
      return;
    } else {
      setComponentLoader(true);
      let apiURL = `/v1/api/time-card/helper/timecard-projects?orgEntityId=${companyId}&searchText=${e}`;

      const res = await get(apiURL);

      if (res.data && res.data.data) {
        const projectsUpdated = res.data.data.map((e:any) => {
          e['projectDisplayNameUI'] = e.projectDisplayNumber + ": " + e.projectName
          return e;
        });
        
        const filteredProjects = filterProjectDropdownOptions(projectsUpdated);
        setProjectsAndSupervisors({...projectsAndSupervisors, filteredProjects});
        setComponentLoader(false);
      }
    }
  };

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {/* Add Jobcode + Service Ticket Popup */}
      <div
        className={
          "add-jobcode-modal create-new-contract " +
          (props.addCodeModal ? "show-contract-popup" : "")
        }
      >
        <div className="contract-backdrop"></div>
        <div className="contract-content modal-content">
          <div className="contract-header">
            <h5> Add Service Ticket </h5>
            <span className="close-icon" onClick={props.toggleAddCode}></span>
          </div>
          <div className="contract-body modal-body">
            <form className="addjobcode-form">
              <div className="row">
                <div className="col-6">
                  <div className="form-group">
                    {componentLoader && (
                      <Loader showComponentLoader={true} />
                    )}
                    <SelectDropdown
                      labelKey="projectDisplayNameUI"
                      options={projectsAndSupervisors.filteredProjects || []}
                      label={"Project"}
                      selected={selectedCostCodeProject || []}
                      onInputChange={handleInputChange}
                      onChange={(e: any) => {
                        if (e?.length && e[0]?.id) {
                          const selected = e[0];
                          const existsInOriginal = projectsAndSupervisors.originalFilteredProjects.find(
                            (project: any) => project.id === selected.id
                          );
                    
                          if (!existsInOriginal) {
                            setProjectsAndSupervisors({...projectsAndSupervisors, 
                              filteredProjects: [selected, ...projectsAndSupervisors.originalFilteredProjects], 
                              originalFilteredProjects: [selected, ...projectsAndSupervisors.originalFilteredProjects]
                            });
                          } else {
                            setProjectsAndSupervisors({...projectsAndSupervisors, 
                              filteredProjects: projectsAndSupervisors.originalFilteredProjects, 
                              originalFilteredProjects: projectsAndSupervisors.originalFilteredProjects
                            });
                          }
                        }

                        setSelectedCostCodeProject(e);
                        if (e && e.length) {
                          getServiceTickets(e, null);
                          getAvailableCode(e);
                        } else {
                          setSelectedServiceTicket([]);
                        }
                      }}
                    />
                  </div>
                  <div className="form-group">
                    {serviceLoader ? (
                      <Loader showComponentLoader={true} />
                    ) : null}
                    <SelectDropdown
                      labelKey="label"
                      options={
                        selectedCostCodeProject && selectedCostCodeProject.length ? [
                          { id: -1, label: 'CREATE SERVICE TICKET' },
                          ...serviceTicketsList.map((e: any) => {
                            return { ...e, label: formatServiceTicketDisplayNumber(e) };
                          })
                        ] : serviceTicketsList.map((e: any) => {
                          return { ...e, label: formatServiceTicketDisplayNumber(e) };
                        })
                      }
                      label={"Service Ticket"}
                      selected={selectedServiceTicket || []}
                      onChange={(e: any) => {
                        if(e[0].id === -1){
                          setSelectedServiceTicket(e);
                          setServiceModal(true);
                        } else {
                          setSelectedServiceTicket(e);
                        }
                      }}
                    />
                  </div>
                  <div className="jobcode-search">
                    <input
                      className="jobcode-search-field jobcode-search-icon"
                      placeholder="Search"
                    ></input>
                    {/* eslint-disable-next-line */}
                    <a
                      // eslint-disable-next-line
                      href="javascript:void(0)"
                      className="jobcode-search-close"
                    >
                      <Close />
                    </a>
                  </div>
                  <p className="jobcode-search-results">
                    Found Cost Codes{" "}
                    {selectedServiceTicket && selectedServiceTicket.length && selectedServiceTicket[0].id !== -1 ? (
                      <b>{projectAvailableCode?.length}</b>
                    ) : (
                      ""
                    )}
                  </p>
                  {selectedServiceTicket && selectedServiceTicket.length && selectedServiceTicket[0].id !== -1 ? (
                    <ul className="available-jobcode-list">
                      {availableCodeLoader ? (
                        <Loader showComponentLoader={true} />
                      ) : null}
                      {projectAvailableCode.map((field: any, index: number) => (
                        <li
                          key={field?.id + " " + index}
                          className="available-jobcode"
                        >
                          {/* eslint-disable-next-line */}
                          <a
                            // eslint-disable-next-line
                            href="javascript:void(0)"
                            className="jobcode-info"
                            onClick={(e) => {
                              e.preventDefault();
                              const id = field?.id;
                              addCode(id);
                            }}
                          >
                            <div className="jobcode-logs">
                              <span className="jobcode-name">
                                {field?.costCodeDescription}
                              </span>
                              <span className="jobcode-id">
                                {field?.labourBudgetCodeId ||
                                  field?.equipmentBudgetCodeId}
                              </span>
                            </div>
                            <div className="ml-auto">
                              {field?.labourBudgetCodeId ? (
                                <span className="jobcode-employee mr-3">
                                  <Employee />
                                </span>
                              ) : null}
                              {field?.equipmentBudgetCodeId ? (
                                <span className="jobcode-employee mr-4">
                                  <Equipment />
                                </span>
                              ) : null}
                              <span className="jobcode-addplus">
                                <Button>
                                  <AddPlus />
                                </Button>
                              </span>
                            </div>
                          </a>
                        </li>
                      ))}
                      {projectAvailableCode?.length === 0 ? (
                        <li className="d-block w-100 text-center">
                          Please select a Project & a Ticket from above fields.
                        </li>
                      ) : (
                        ""
                      )}
                    </ul>
                  ) : (
                    ""
                  )}
                </div>
                <div className="col-6">
                  <p className="jobcode-search-results mt-0">
                    Assigned Cost Codes(<b>{props.assignedCode.length}</b>)
                  </p>
                  <ul className="available-jobcode-list list-right">
                    {props.assignedCode.map((field: any, index: number) => (
                      <li
                        key={field?.id + " " + index}
                        className="available-jobcode"
                      >
                        <div className="jobcode-info">
                          <div className="jobcode-logs">
                            <span className="jobcode-name">
                              {field?.serviceTicketId
                                ? field?.serviceTicketId + " : "
                                : ""}
                              {field?.costCodeDescription}
                            </span>
                            <span className="jobcode-id">
                              {(field?.serviceTicketDisplayNumber
                                ? formatServiceTicketDisplayNumber(field) + " : "
                                : "") + field?.labourBudgetCodeId ||
                                field?.equipmentBudgetCodeId}
                            </span>
                          </div>
                          <div className="ml-auto">
                            {field?.labourBudgetCodeId ? (
                              <span className="jobcode-employee mr-3">
                                <Employee />
                              </span>
                            ) : null}
                            {field?.equipmentBudgetCodeId ? (
                              <span className="jobcode-employee mr-4">
                                <Equipment />
                              </span>
                            ) : null}
                            {/* eslint-disable-next-line */}
                            <a
                              // eslint-disable-next-line
                              href="javascript:void(0)"
                              className="jobcode-addplus"
                              onClick={() => deleteAssignedCode(index)}
                            >
                              <TrashRed />
                            </a>
                          </div>
                        </div>
                      </li>
                    ))}
                    {props.assignedCode.length === 0 ? (
                      <li className="d-block w-100 text-center">
                        Please select from Available Cost Codes.
                      </li>
                    ) : (
                      ""
                    )}
                    <li ref={listEndRef} style={{ height: 50 }}></li>
                  </ul>
                </div>
              </div>
              <div className="contract-footer modal-footer">
                <button
                  className="btn btn-secondary"
                  onClick={(e) => {
                    e.preventDefault();
                    props.toggleAddCode();
                  }}
                >
                  Cancel
                </button>
                <button
                  className="btn btn-primary"
                  onClick={(e) => {
                    e.preventDefault();
                    saveCode();
                  }}
                >
                  Save
                </button>{" "}
              </div>
            </form>
            {serviceModal && (
              <ServiceTicketCreate
                toggle={() => setServiceModal(!serviceModal)}
                modal={serviceModal}
                projectId={selectedCostCodeProject[0].id}
                updateDropdownTimecard={async (res: any) => {
                  await getServiceTickets(selectedCostCodeProject, res.id);
                }}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default AddCodeModal;
